.slider-card {
  background-color: #181826;
  border: 1px solid #181826;
  border-radius: 8px;
  padding-bottom: 20px;
  padding-top: 10px;
  color: white;
  margin: 20px 5px;
  align-items: center;
  max-width: 600px;
}

.slider-text {
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.slider-container {
  display: flex;
  align-items: center;
}

.slider-description {
  flex: 1;
  font-size: 12px;
}

.slider {
  flex: 4;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px; /* Set the width of the slider handle */
  height: 25px; /* Set the height of the slider handle */

  border-radius: 50%; /* Make the slider handle round */
  cursor: pointer;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .slider-card {
    padding: 15px;
  }

  .slider-text {
    font-size: 16px;
  }

  .slider-description {
    font-size: 12px;
  }
}
