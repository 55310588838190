.reas-card {
  background-color: #181826;
  color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px; /* Add margin-bottom for space between cards */
}

.reas-card .empathie-options {
  align-items: left;
}

.reas-card label {
  font-weight: bold;
  font-size: large;
  margin-bottom: 20px;
}

.reas-card input[type="radio"] {
  display: none;
}

.reas-card input[type="radio"] + label {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0;
  color: #181826;
  background-color: #e0e0e0;
  transition: background-color 0.3s ease;
  width: 20rem;
  font-weight: normal;
}

.reas-card input[type="radio"]:checked + label {
  background-color: #4caf50;
  color: white;
}

.button-container {
  display: flex;
  align-items: center;
}

/* Responsive styling */
@media screen and (max-width: 768px) {
  .reas-card {
    width: 100%;
    padding: 15px;
  }

  .reas-card label {
    font-size: larger;
  }
  .reas-card input[type="radio"] + label {
    width: 18rem; /* Set full width for labels on smaller screens */
  }
}
