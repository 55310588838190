.footer {
  color: white;
  background-color: #181826;
  padding: 20px;
  flex-shrink: 0;
}

.main {
  /* padding: 20px; */
  min-height: 100vh;
  background-color: white;
}

body {
  background-color: #181826;
  line-height: 1.5;
  padding: 0;
}

input[type="radio"] {
  -webkit-appearance: radio;
}
