@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Red Hat Display", Helvetica, Arial, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "Gabarito", Helvetica, Arial, sans-serif;
  font-weight: 800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Für Webkit-Browser wie Chrome, Safari */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #000;
}

/* Für Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #000 #000;
}
