*,
*:after,
*:before {
  box-sizing: border-box;
}

.card-container {
  width: 22rem;
  margin: 0 auto;
}

.card {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 425px;
  background-color: #181826;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(#999, 0.25);
  padding: 0.75rem;
}

.card-image {
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 65%;
  background-image: url("https://assets.codepen.io/285131/coffee_1.jpg");
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: 0 5%;
  position: relative;
}

.card-heading {
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
}

/*.card-form {
  padding: 0 1rem 0;
}*/

.input {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1rem;
  border-radius: 10px;
  & + .input {
    margin-top: 0;
  }
}

.input-label {
  color: #5d676d;
  position: absolute;
  font-size: 1rem;
  top: 1rem;
  left: 0.5rem;
  transition: 0.25s ease;
}

.input-field {
  border: 0;
  z-index: 1;
  background-color: #fff;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 10px;
  &:focus,
  &:valid {
    outline: 0;
    border-bottom-color: #6658d3;
    & + .input-label {
      color: #6658d3;
      transform: translateY(-1.5rem);
    }
  }
}

.action {
  margin-top: 2rem;
}

.eigButton {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 22px;
  border: 0px solid black;
  font-size: 18px;
  font-weight: bold;
  background-color: rgb(237, 139, 12);
  color: #fff;
}
.eigButton:active {
  transform: scale(0.95);
}

.card-info {
  padding-top: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  color: #8597a3;
  a {
    display: block;
    color: #6658d3;
    text-decoration: none;
  }
}

.eigButton {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 22px;
  border: 0px solid black;
  font-size: 18px;
  font-weight: bold;
  background-color: rgb(227, 148, 44);
  color: #fff;
  margin: 0 auto;
}
.eigButton:active {
  transform: scale(0.95);
}

.logo-container {
  margin: 1rem;
  width: 4rem;
}